* {
  font-family: 'Montserrat', sans-serif;
  color: #000; }

html, body {
  position: relative;
  height: 100%;
  margin: 0px;
  padding: 0px;
  color: #000;
  font-size: 1.8rem;
  font-weight: 300; }

a, a:hover, a:active, a:visited, a:focus {
  text-decoration: none; }

p {
  padding: 0px;
  margin: 0px; }

img {
  -webkit-backface-visibility: visible;
          backface-visibility: visible; }

.full-height-container {
  width: calc(100% - 2 * 16rem);
  min-height: calc(100vh - 8.5rem - 2rem);
  margin: 0 auto;
  padding: 8.5rem 0 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  background: #fff; }

@media screen and (max-width: 1366px) {
  .full-height-container {
    padding-top: 7rem;
    width: calc(100% - 2 * 12rem - 2rem);
    min-height: calc(100vh - 7rem - 2rem); } }

@media screen and (max-width: 1024px) {
  .full-height-container {
    padding-top: 6.2rem;
    width: calc(100% - 2 * 8rem - 2rem);
    min-height: calc(100vh - 6.2rem - 2rem); } }

@media screen and (max-width: 768px) {
  .full-height-container {
    width: calc(100% - 2rem);
    padding-left: 1rem;
    padding-right: 1rem; } }

html {
  font-size: 10px; }
  @media screen and (min-width: 2559px) {
    html {
      font-size: 14px; } }
  @media screen and (min-width: 3839px) {
    html {
      font-size: 21px; } }
  @media screen and (max-width: 1600px) {
    html {
      font-size: 9px; } }
  @media screen and (max-width: 1280px) {
    html {
      font-size: 8.5px; } }
  @media screen and (max-width: 1024px) {
    html {
      font-size: 8px; } }

p {
  color: inherit; }

.dot-list li {
  list-style: none;
  position: relative; }
  .dot-list li:before {
    display: block;
    position: absolute;
    content: '';
    height: 0.35em;
    width: 0.35em;
    border-radius: 50%;
    border: 1px solid #000;
    top: 50%;
    left: 0;
    -webkit-transform: translate(-175%, -50%);
            transform: translate(-175%, -50%); }

@media screen and (max-width: 768px) {
  .dot-list li:before {
    top: 0;
    -webkit-transform: translate(-175%, 0.35em);
            transform: translate(-175%, 0.35em); } }

.contact-title {
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: 1.5em;
  text-transform: uppercase; }

.contact-data {
  margin: 0 auto;
  font-size: 2.4rem; }

.social-icons {
  margin-top: 1em;
  text-align: center; }
  .social-icons svg {
    width: 4rem;
    margin: 0 0.5em; }

#contact-form {
  max-width: 55rem;
  margin: 4rem auto 0; }

.input, .textarea {
  width: 100%;
  background: transparent;
  outline: none;
  border: none; }
  .input::-webkit-input-placeholder, .textarea::-webkit-input-placeholder {
    color: #c6c6c6;
    letter-spacing: 0.05em;
    font-weigth: 300; }
  .input:-moz-placeholder, .textarea:-moz-placeholder {
    color: #c6c6c6;
    letter-spacing: 0.05em;
    font-weigth: 300; }
  .input::-moz-placeholder, .textarea::-moz-placeholder {
    color: #c6c6c6;
    letter-spacing: 0.05em;
    font-weigth: 300; }
  .input:-ms-input-placeholder, .textarea:-ms-input-placeholder {
    color: #c6c6c6;
    letter-spacing: 0.05em;
    font-weigth: 300; }

.input {
  width: 100%;
  margin: 0.3em auto;
  padding-bottom: 0.3em;
  border: none;
  border-bottom: 1px solid #000; }

.textarea {
  border: 1px solid #000;
  padding: 0.5em;
  margin-top: 1em;
  margin-bottom: 0.5rem;
  resize: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.error-hint {
  color: red;
  font-size: 0.8em;
  display: none; }

.error-validate + .error-hint {
  display: block; }

.send-btn {
  outline: none;
  display: table;
  cursor: pointer;
  margin: 1rem 0;
  font-size: 1.8rem;
  text-transform: uppercase;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  font-weight: bold;
  padding: .7em 2.2em;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out; }
  .send-btn:hover {
    color: #fff;
    background: #000;
    -webkit-box-shadow: 0 0 1px #000;
            box-shadow: 0 0 1px #000; }

.send-btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

#sending, #thank-you, #error {
  display: none; }
  #sending.is-visible, #thank-you.is-visible, #error.is-visible {
    display: block; }

@media screen and (max-width: 1024px) {
  .contact-title, .contact-data {
    font-size: 2.2rem; } }

@media screen and (max-width: 768px) {
  .contact-title, .contact-data {
    font-size: 2rem; }
  #contact-form {
    margin-top: 4rem; } }

.experiance-container {
  max-width: 55em;
  margin: 0 auto; }
  .experiance-container p {
    margin-bottom: 2em; }
  .experiance-container .spacer {
    margin-bottom: 6rem; }
  .experiance-container .desc-spacer {
    margin-bottom: 3rem; }
  .experiance-container .dot-list {
    padding-left: 0.8em; }

.date-begin {
  width: 2.6em;
  display: inline-block; }

@media screen and (max-width: 768px) {
  .experiance-container {
    font-size: 1.9rem; }
    .experiance-container .dot-list {
      padding-left: 2rem; }
    .experiance-container .spacer {
      margin-bottom: 3rem; } }

#portfolio {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.portfolio-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%; }

.assistant {
  font-weight: 700;
  text-align: center;
  margin: 2em auto;
  text-transform: uppercase; }

.portfolio-item {
  position: relative;
  width: 13%;
  margin: 0 0.64% 1.28%;
  cursor: pointer;
  outline: none; }
  .portfolio-item img {
    width: 100%;
    display: block; }
  .portfolio-item:hover .portfolio-hint {
    visibility: visible;
    opacity: 1; }
  .portfolio-item.video {
    margin: 0 3.02% 3.02%;
    width: 27.28%;
    position: relative;
    height: 0;
    padding-bottom: 15.345%; }
  .portfolio-item iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.portfolio-hint {
  width: 7em;
  max-width: 98%;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #fff;
  z-index: 2;
  text-align: center;
  font-size: 0.85em;
  color: #c6c6c6;
  padding: 1em;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out; }

@media screen and (max-width: 1660px) {
  .portfolio-item {
    width: 15.38%; } }

@media screen and (max-width: 1366px) {
  .portfolio-item {
    width: 18.72%; }
    .portfolio-item.video {
      margin: 0 1% 2%;
      width: 31.33%;
      padding-bottom: 17.6231%; } }

@media screen and (max-width: 1024px) {
  .portfolio-item {
    width: 24%;
    margin: 0 0.5% 1%; }
    .portfolio-item.video {
      margin: 0 2.5% 5%;
      width: 45%;
      padding-bottom: 25.3125%; } }

@media screen and (max-width: 609px) {
  .portfolio-item {
    width: 32%; }
    .portfolio-item.video {
      margin: 0 1% 2%;
      width: 48%;
      padding-bottom: 27%; } }

@media screen and (max-width: 539px) {
  .portfolio-item.video {
    margin: 0 auto 1%;
    width: 300px;
    padding-bottom: 168.75px; } }

@media screen and (max-width: 459px) {
  .portfolio-item {
    width: 49%; } }

.project-container {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.no-project {
  text-align: center; }

.project-slide {
  outline: none; }

.project-image {
  height: 80vh;
  min-height: 60rem;
  max-width: 99.5%;
  margin: 0 auto; }
  .project-image.horizontal {
    min-height: 40rem;
    height: 55vh; }

.slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.project-description-container {
  min-width: 20em;
  padding: 1em;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.project-description {
  color: #c6c6c6;
  font-size: 1.8rem;
  font-weight: 500; }
  .project-description * {
    margin-bottom: 0.15em; }

.magazine {
  font-size: 1.05em;
  text-transform: uppercase; }

.title {
  font-size: 1.3em;
  text-transform: uppercase;
  font-weight: 600;
  color: #8A8A8A; }

.photo {
  margin-top: 1.5em; }

.slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 1.5rem; }
  .slick-dots li {
    height: 1.2rem;
    width: 1.2rem;
    margin: 0 0.8rem; }
    .slick-dots li button {
      border: 1px solid #c6c6c6;
      border-radius: 50%;
      height: 100%;
      width: 100%; }
      .slick-dots li button:before {
        display: none; }
  .slick-dots .slick-active button {
    background-color: #c6c6c6; }

@media screen and (min-width: 1920px) {
  .project-image {
    min-height: 80rem; } }

@media screen and (max-width: 768px) {
  .slick-dots {
    bottom: auto;
    top: -40px; }
  .project-slider {
    margin-top: 45px;
    margin-bottom: 0px !important; } }

@media screen and (max-width: 540px) {
  .slick-dots li {
    height: 8px;
    width: 8px;
    margin: 0 .4rem; }
    .slick-dots li button {
      padding: 4px; } }

@media screen and (max-width: 459px) {
  .project-description-container {
    min-width: 10em; }
  .project-image {
    min-height: 40rem;
    height: auto;
    width: 99.5%; }
    .project-image.horizontal {
      min-height: 25rem;
      height: auto; }
  .slick-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; } }

#loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  background-size: cover;
  z-index: 20;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.pace {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 21;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1rem;
  width: 30rem;
  border-radius: 1rem;
  border: 1px solid #000;
  overflow: hidden; }

.pace-progress {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  position: fixed;
  z-index: 2000;
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  width: 100%;
  background: #000; }

.pace.pace-inactive {
  display: block; }

#circle {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center; }

#circle text {
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  fill: #000; }

#circle svg {
  width: 30rem;
  height: 30rem;
  max-width: 100%;
  -webkit-animation: rotate 5s infinite linear;
          animation: rotate 5s infinite linear; }

#circle {
  top: -4rem; }

.pace {
  top: 34rem; }

.index #circle text {
  fill: #fff; }

.index .pace {
  border: 1px solid #fff; }

.index .pace-progress {
  background: #fff; }

.index #loader {
  background: url(../../img/loader_bg.jpg) center center no-repeat;
  background-size: cover; }

.index #loader-helper {
  display: block;
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 22;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: z-index 2.4s step-end, -webkit-transform 1.2s 1.2s cubic-bezier(0.75, 0.05, 0, 1);
  transition: z-index 2.4s step-end, -webkit-transform 1.2s 1.2s cubic-bezier(0.75, 0.05, 0, 1);
  transition: transform 1.2s 1.2s cubic-bezier(0.75, 0.05, 0, 1), z-index 2.4s step-end;
  transition: transform 1.2s 1.2s cubic-bezier(0.75, 0.05, 0, 1), z-index 2.4s step-end, -webkit-transform 1.2s 1.2s cubic-bezier(0.75, 0.05, 0, 1); }

.index #loader {
  -webkit-transition: z-index 2.4s step-end;
  transition: z-index 2.4s step-end; }

.index .pace {
  -webkit-transition: z-index 2.4s step-end;
  transition: z-index 2.4s step-end; }

.index #menu, .index .full-height-container {
  -webkit-transition: opacity 1s 2.6s ease-in-out;
  transition: opacity 1s 2.6s ease-in-out; }

#loader {
  -webkit-transition: z-index 0.75s step-end;
  transition: z-index 0.75s step-end; }

.pace {
  -webkit-transition: z-index 0.75s step-end;
  transition: z-index 0.75s step-end; }

#menu, .full-height-container {
  opacity: 0;
  -webkit-transition: opacity 1s 0.75s ease-in-out;
  transition: opacity 1s 0.75s ease-in-out; }

.pace-done.index #menu {
  -webkit-transition-delay: 3.3s;
          transition-delay: 3.3s; }

.pace-done.index #loader-helper {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  z-index: -1; }

.pace-done .pace, .pace-done #loader {
  z-index: -2; }

.pace-done #menu, .pace-done .full-height-container {
  opacity: 1; }

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0); } }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0); } }

#menu {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 8.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 10;
  background: #fff; }

#menu-trigger {
  display: none;
  position: relative;
  width: 28px;
  height: 20px;
  cursor: pointer; }
  #menu-trigger span {
    display: block;
    position: absolute;
    top: 0;
    background: #000;
    width: 100%;
    left: 0;
    height: 4px;
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    -webkit-transform-origin: left center;
            transform-origin: left center; }
    #menu-trigger span:nth-child(2) {
      top: 50%;
      -webkit-transform: rotate(0) translateY(-50%);
              transform: rotate(0) translateY(-50%); }
    #menu-trigger span:nth-child(3) {
      top: auto;
      bottom: 0; }
  #menu-trigger.is-open span {
    -webkit-transform: rotateZ(45deg) translateY(-2px);
            transform: rotateZ(45deg) translateY(-2px); }
    #menu-trigger.is-open span:nth-child(2) {
      opacity: 0;
      -webkit-transform: translateX(100%);
              transform: translateX(100%); }
    #menu-trigger.is-open span:nth-child(3) {
      -webkit-transform: rotateZ(-45deg) translateY(2px);
              transform: rotateZ(-45deg) translateY(2px); }

.menu-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  list-style: none; }

.menu-item {
  text-transform: uppercase;
  margin-right: 3em;
  font-weight: bold; }
  .menu-item.active .menu-link {
    color: #000; }

.menu-link {
  color: #c6c6c6;
  letter-spacing: 0.04em; }

.logotype-container, .positioned {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 16rem;
  background: #fff; }

.positioned {
  right: 0;
  left: auto;
  margin-right: 0; }
  .positioned .menu-link {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg);
    display: block; }

.logotype {
  display: block;
  text-transform: uppercase;
  -webkit-transform: rotateZ(-90deg);
          transform: rotateZ(-90deg);
  text-align: center;
  white-space: nowrap;
  font-weight: bold; }

@media screen and (max-width: 1366px) {
  .logotype-container, .positioned {
    width: 12rem; }
  #menu {
    height: 7rem; } }

@media screen and (max-width: 1024px) {
  .logotype-container, .positioned {
    width: 8rem; }
  #menu {
    height: 6.2rem;
    font-size: 1.8rem; } }

@media screen and (max-width: 768px) {
  #menu {
    padding: 0 1rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .logotype-container, .positioned {
    position: relative;
    width: auto;
    display: block; }
  .logotype, .positioned .menu-link {
    -webkit-transform: none;
            transform: none; }
  .logotype {
    font-size: 1.8rem;
    white-space: normal;
    max-width: 11em; }
  .menu-list {
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 6.2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-transition: 0.75s ease-in-out;
    transition: 0.75s ease-in-out;
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
    .menu-list.is-open {
      -webkit-transform: translateY(0);
              transform: translateY(0); }
  .menu-item {
    margin: 0 auto 2em;
    width: 100%;
    text-align: center; }
  #menu-trigger {
    display: block; } }

.stories-thumbnails-container {
  padding-left: 5em; }
  .stories-thumbnails-container .portfolio-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .stories-thumbnails-container .portfolio-hint {
    padding: 0.5em; }

.stories-title {
  font-weight: bold;
  font-size: 4rem;
  position: relative;
  -webkit-transform: translateY(40%);
          transform: translateY(40%);
  color: #c1c1c1;
  z-index: 2; }

.stories-thumbnails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  max-width: 24em; }
  .stories-thumbnails .portfolio-item {
    width: 31.5%;
    margin: 0 0.75% 1.5%; }

.back-projects {
  margin: 1rem auto 0;
  text-align: center;
  display: table;
  color: #c6c6c6;
  font-weight: 600; }
  .back-projects:hover .back-arrow {
    -webkit-box-shadow: 1px 1px 5px #c6c6c6;
            box-shadow: 1px 1px 5px #c6c6c6; }

.back-arrow {
  height: 5rem;
  width: 5rem;
  margin: 0 auto 0.25em;
  border: 2px solid #c6c6c6;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out; }
  .back-arrow img {
    max-width: 85%;
    margin: 0 auto;
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%); }

@media screen and (max-width: 1660px) {
  .stories-thumbnails {
    max-width: 21em; } }

@media screen and (max-width: 1024px) {
  .other-stories {
    margin: 0 auto; }
  .stories-thumbnails-container {
    padding-left: 1em; }
  .stories-thumbnails {
    max-width: 23em; } }

@media screen and (max-width: 459px) {
  .other-stories {
    width: 23em; }
  .stories-title {
    font-size: 3.75rem;
    white-space: nowrap; } }
